import React from "react";
import styled from "styled-components";
import { colors } from "../../styles";
import { ButtonLink } from "../../compoenents/designSystems/Button";
import { Typography } from "../../compoenents/designSystems/Typography";
import PaperAirplane from "../../compoenents/designSystems/Images/paperAirplane.png";
import { AnonymousLayout } from "../../compoenents/common/Layout/AnonymousLayout";

const Confirm: React.FC = () => {
  return (
    <AnonymousLayout>
      <StyledDiv>
        <Typography level={5} className="Typography">
          新規会員登録
        </Typography>
        <img src={PaperAirplane} alt="" />
        <Message>
          入力頂いたメールアドレスに会員登録確認のメールを配信しました。
          <br />
          メールをご確認いただき記載されているURLから会員登録を完了させてください。
        </Message>
        <StyledButton color="theme" padding="17px 14px" to="/">
          TOPへ
        </StyledButton>
      </StyledDiv>
    </AnonymousLayout>
  );
};

const StyledDiv = styled.div`
  padding: 28px 24px 64px;
  border-top: 1px solid ${colors.boundary};

  img {
    width: 72px;
    height: 72px;
    margin: 48px auto 0;
    display: block;
  }
`;

const Message = styled.p`
  line-height: 26px;
  margin: 32px 0 0;
  padding: 0;
  font-size: 13px;
  text-align: center;
`;

const StyledButton = styled(ButtonLink)`
  margin-top: 32px;
`;

export default Confirm;
