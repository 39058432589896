import { viewports } from './variables';

export const media: { [key: string]: string } = Object.keys(viewports).reduce((acc: object, label: string) => {
  const pascalCaseLabel: string = label.charAt(0).toUpperCase() + label.slice(1);
  return {
    ...acc,
    [`min${pascalCaseLabel}`]: `@media (min-width: ${viewports[label]}px)`,
    [`max${pascalCaseLabel}`]: `@media (max-width: ${viewports[label] - 1}px)`,
  };
}, {});
