import axios from "../libs/axios";

export default class Repository {
  public static async post<T>(
    path: string,
    payload: object,
    config?: any
  ): Promise<{ data: T; status: number }> {
    const response = await axios.post<T>(path, payload, config);
    return { data: response.data, status: response?.status };
  }
}
