import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles";
import Warning from "../Icons/Warning.svg";

interface Props {
  label?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  type?: string;
  value?: string;
  name?: string;
  errorMessage?: string;
  className?: string;
  height?: string | number;
  ref?: React.LegacyRef<HTMLInputElement>;
  autoComplete?: string;
  showWarning?: boolean;
}

export const Input: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  label,
  placeholder,
  maxLength,
  disabled,
  type,
  required,
  value,
  name,
  errorMessage,
  onChange,
  className,
  height,
  autoComplete,
  showWarning = false,
}) => {
  const [count, setCount] = useState(0);
  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    callback: { (e: React.ChangeEvent<HTMLInputElement>): void }
  ) => {
    setCount(event.target.value.length);
    callback(event);
  };

  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <InputForm
          placeholder={placeholder}
          disabled={disabled}
          showWarning={showWarning}
          type={type}
          required={required}
          value={value}
          name={name}
          error={errorMessage}
          onChange={onChange ? (e) => handleOnChange(e, onChange) : undefined}
          maxLength={maxLength}
          height={height}
          autoComplete={autoComplete}
        />
        {showWarning && <StyledWarning error={errorMessage} />}
      </InputWrapper>
      {(errorMessage || maxLength) && (
        <BottomContent>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {maxLength && (
            <Count>
              {count}/{maxLength}
            </Count>
          )}
        </BottomContent>
      )}
    </Wrapper>
  );
};

export const Textarea: React.FC<
  Props & React.InputHTMLAttributes<HTMLTextAreaElement>
> = ({
  label,
  placeholder,
  maxLength,
  disabled,
  required,
  value,
  name,
  errorMessage,
  onChange,
  height,
}) => {
  const [count, setCount] = useState(0);
  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    callback: { (e: React.ChangeEvent<HTMLTextAreaElement>): void }
  ) => {
    console.log("event.target.value.length", event.target.value.length);
    setCount(event.target.value.length);
    callback(event);
  };

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <TextareaForm
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          value={value}
          name={name}
          error={errorMessage}
          onChange={onChange ? (e) => handleOnChange(e, onChange) : undefined}
          maxLength={maxLength}
          height={height}
        />
        <StyledWarning error={errorMessage} />
      </InputWrapper>
      {(errorMessage || maxLength) && (
        <BottomContent>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {maxLength && (
            <Count>
              {count}/{maxLength}
            </Count>
          )}
        </BottomContent>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 12px;
  color: ${colors.textMedium};
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Form = styled.input<{
  error: Props["errorMessage"];
  height: Props["height"];
  showWarning?: Props["showWarning"];
}>`
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  height: ${({ height }) =>
    typeof height === "string" ? `${height}` : height ? `${height}px` : "auto"};
  max-width: 100%;
  padding: 8px 40px 8px 16px;
  border: 2px solid;
  border-color: ${({ error, showWarning }) =>
    error && showWarning ? colors.attentionRed : colors.boundary};
  border-radius: 8px;
  background: ${colors.surface};
  appearance: none;
  outline: none;

  ::placeholder {
    color: ${colors.textLight};
  }

  :focus {
    border: 2px solid ${colors.theme};
  }

  :disabled {
    opacity: 0.5;
  }
`;

const InputForm = Form.withComponent("input");

const TextareaForm = Form.withComponent("textarea");

const SVGWarning = () => {
  return <img src={Warning} alt="" />;
};
const StyledWarning = styled(SVGWarning)<{ error: Props["errorMessage"] }>`
  display: ${({ error }) => (error ? "block" : "none")};
  color: ${colors.attentionRed};
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${colors.attentionRed};
`;

const Count = styled.div`
  font-size: 12px;
  color: ${colors.textLight};
  margin-left: auto;
`;
