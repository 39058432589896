import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../styles";

interface ButtonProps {
  Icon?: React.ComponentType;
  buttonsize?: "large" | "small";
  color?: "theme" | "gradient" | "black" | "red" | "white" | "orange";
  disabled?: boolean;
  height?: number;
  padding?: string;
  width?: number | string;
  border?: boolean;
  className?: string;
  alignicon?: "left" | "right";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  flex?: number;
}

function getBackgroundColor(color: ButtonProps["color"]): string {
  switch (color) {
    case "theme":
      return colors.theme;
    case "gradient":
      return colors.themeGradient;

    case "red":
      return colors.attentionRed;

    case "white":
      return "#fff";
    case "orange":
      return "#F1A354";
    // When it's dsiabled, it should be base on black.
    case "black":
    default:
      return colors.textDark;
  }
}

const baseStyle = css<ButtonProps>`
  align-content: center;
  align-items: center;
  flex-direction: ${({ alignicon }) =>
    alignicon === "left" ? "row" : "row-reverse"};
  background: ${(props) => getBackgroundColor(props.color)};
  border-radius: 8px;
  border: ${({ border, color }) =>
    border
      ? `1px solid ${
          color === "white" ? colors.boundary : getBackgroundColor(color)
        }`
      : "none"};
  box-sizing: border-box;
  color: ${({ color }) => (color === "white" ? colors.textDark : "#fff")};
  display: flex;
  font-weight: ${({ color }) => (color === "white" ? "bold" : "normal")};
  font-size: ${({ buttonsize }) => (buttonsize === "small" ? 12 : 14)}px;
  height: ${({ height }) => `${height}px` || "auto"};
  justify-content: center;
  line-height: 1;
  opacity: ${({ disabled }): number => (disabled ? 0.2 : 1)};
  outline: none;
  padding: ${({ padding, buttonsize }) =>
    typeof padding !== "undefined"
      ? padding
      : buttonsize === "small"
      ? "12px 10px"
      : "14px 12px"};
  text-align: center;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  flex: ${({ flex }) => (flex ? flex : "")};
`;

const IconStyle = css`
  width: 100%;
  height: auto;
`;
const IconWrapper = styled.div<ButtonProps>`
  margin-right: ${({ buttonsize, alignicon }) =>
    alignicon === "right" ? 0 : buttonsize === "small" ? 5 : 10}px;
  margin-left: ${({ buttonsize, alignicon }) =>
    alignicon === "left" ? 0 : buttonsize === "small" ? 5 : 10}px;
  width: ${({ buttonsize }) => (buttonsize === "small" ? 13 : 16)}px;
  display: flex;
`;

const LinkWithStyle = styled(Link)`
  ${baseStyle};
`;
const ButtonWithStyle = styled.button`
  ${baseStyle};
`;

export const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, Icon, alignicon = "left", buttonsize, ...restProps }) => {
  const IconWithStyle =
    Icon &&
    styled(Icon)`
      ${IconStyle};
    `;

  return (
    <ButtonWithStyle
      {...restProps}
      buttonsize={buttonsize}
      alignicon={alignicon}
    >
      {IconWithStyle && (
        <IconWrapper buttonsize={buttonsize} alignicon={alignicon}>
          <IconWithStyle />
        </IconWrapper>
      )}
      {children}
    </ButtonWithStyle>
  );
};

export const ButtonLink: React.FC<ButtonProps & LinkProps> = ({
  children,
  Icon,
  alignicon = "left",
  buttonsize,
  ...restProps
}) => {
  const IconWithStyle =
    Icon &&
    styled(Icon)`
      ${IconStyle};
    `;

  return (
    <LinkWithStyle {...restProps} buttonsize={buttonsize} alignicon={alignicon}>
      {IconWithStyle && (
        <IconWrapper buttonsize={buttonsize} alignicon={alignicon}>
          <IconWithStyle />
        </IconWrapper>
      )}
      {children}
    </LinkWithStyle>
  );
};
