import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { darkColors } from "../../../styles";
import Logo from "../Images/logotype.png";

const Container = styled.div`
  background-color: ${darkColors.background};
  font-size: 14px;
  line-height: 1.4;
  padding: 20px 16px;
`;
const MenuList = styled.ul`
  color: ${darkColors.textLight};
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
  & > li {
    margin: 16px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
`;
const License = styled.p`
  color: ${darkColors.textMedium};
  margin: 16px 0;
`;
const Copyright = styled.div`
  align-items: flex-end;
  color: ${darkColors.textDark};
  font-size: 9px;
  display: flex;
  justify-content: space-between;
`;

export const Footer: React.FC<{}> = React.memo(
  () => (
    <Container>
      <MenuList>
        <li className="text-white">
          <Link to="/law">特定商取引法に基づく表記</Link>
        </li>
        <li>
          <a
            href="https://afternooninc.notion.site/d7dd3b13d2da4f8c852ea93e818354c6"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
        </li>
        <li>
          <Link to="/helplist">お問い合わせ</Link>
        </li>
        <li>
          <Link to="/corp">運営会社</Link>
        </li>
      </MenuList>
      <License>
        インターネット異性紹介事業届出済み
        <br />
        受理番号：3019-0063-000
      </License>
      <Copyright>
        <Link to="/">
          <img
            src={Logo}
            style={{ color: "rgba(255, 255, 255, 0.5)" }}
            alt=""
          />
        </Link>
        <span className="text-12px">&copy;2019 Afternoon. Inc.</span>
      </Copyright>
    </Container>
  ),
  () => true
);
