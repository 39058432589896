import axios from "axios";

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 12000,
});

service.interceptors.request.use((config) => {
  const intercept = config;

  return intercept;
});

export default service;
