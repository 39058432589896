import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { colors } from "../styles";

import { LogoBar } from "../compoenents/designSystems/Appbars";

// images
import regButton from "../compoenents/designSystems/Images/regButton.png";
import HeroImg from "../assets/lp/Hero.png";
import RibbonMedal from "../assets/lp/AN_3rd.png";
import AboutAfternoon from "../assets/lp/about-afternoon.png";
import Beer from "../assets/lp/bear.svg";
import Bubble from "../assets/lp/bubble.svg";
import Tennis from "../assets/lp/tennis.svg";
import Couple from "../assets/lp/couple.svg";
import Alarm from "../assets/lp/alarm.svg";
import EmojiCouple from "../assets/lp/emoji _man_and_woman holding_hands.svg";
import Mail from "../assets/lp/mail.svg";
import Hands from "../assets/lp/hands.svg";
import SearchUser1 from "../assets/lp/search_user1.png";
import SearchUser2 from "../assets/lp/search_user2.png";
import SearchUser3 from "../assets/lp/search_user3.png";
import chevronRight from "../assets/lp/chevron_right.svg";
import Shield from "../assets/lp/shield.svg";
import PerformingArt from "../assets/lp/performing_arts.svg";
import Memo from "../assets/lp/memo.svg";
import User01 from "../assets/lp/user01.svg";
import User02 from "../assets/lp/user02.svg";
import User03 from "../assets/lp/user03.svg";
import Badge from "../assets/lp/badge.svg";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Footer } from "../compoenents/designSystems/Blocks";
import { isIphone, isSafari } from "../libs/utils/browserUtil";
const MakeLandingPageComponent = () => {
  useEffect(() => {
    setTimeout(() => {
      //@ts-ignore
      window.dataLayer.push({ event: "optimize.activate" });
    }, 200);
  }, []);
  const [registerButtonInview, setRegisterButtonInview] = useState(true);
  const [isShowSwingBtn, setIsShowSwingBtn] = useState(true);
  const changeBottom = useCallback(() => {
    const bottomPosition =
      document.body.offsetHeight - (window.scrollY + window.innerHeight);
    if (bottomPosition <= 200) {
      setIsShowSwingBtn(false);
    } else {
      setIsShowSwingBtn(true);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", changeBottom);
    return () => window.removeEventListener("scroll", changeBottom);
  }, []);

  return (
    <div className="m-auto" style={{ maxWidth: "600px" }}>
      <Header>
        <LogoBar />
        <div className="buttons">
          <button
            className="ml-2 text-white w-20 h-8 "
            style={{ backgroundColor: "#e6B27e", borderRadius: "4px" }}
          >
            <a
              href="https://www.after-noon.jp/login"
              target="_blank"
              rel="noreferrer"
              className="text-14px w-full h-full flex items-center justify-center"
            >
              ログイン
            </a>
          </button>
        </div>
      </Header>
      <EyeCatch>
        <Background src={HeroImg} />
        <Medal src={RibbonMedal} />
        <Title>
          <p>結婚後も</p>
          <p>素敵なつながりを</p>
          <p
            className="font-black"
            style={{ fontSize: "16px", marginTop: "12px" }}
          >
            既婚者限定コミュニティ
          </p>
        </Title>
        <InView
          style={{
            height: "110px",
            marginTop: `${isIphone() && isSafari() ? "-190px" : ""}`,
          }}
          onChange={setRegisterButtonInview}
        >
          <Link to="/signup/input">
            <RegistrationButtonWrapper>
              <SwingAnimationImg
                className="reg-button-move"
                style={{
                  position: "absolute",
                  maxWidth: "550px",
                  width: "90vw",
                }}
                src={regButton}
              />
            </RegistrationButtonWrapper>

            {isShowSwingBtn && (
              <RegistrationButtonWrapperFix displayFlg={registerButtonInview}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "600px",
                  }}
                >
                  <SwingAnimationImg
                    className="reg-button-move"
                    style={{
                      maxWidth: "550px",
                      width: "90vw",
                    }}
                    src={regButton}
                  />
                </div>
              </RegistrationButtonWrapperFix>
            )}
          </Link>
        </InView>
      </EyeCatch>
      <FirstSection>
        <h2 className="text-2xl font-black">Afternoon.とは？</h2>
        <AppExplanation>
          結婚後でも、既婚者同士でお友達を作り人生を充実させたい方
          のための既婚者限定のコミュニティです。
        </AppExplanation>
        <div className="mt-4">
          <img src={AboutAfternoon} alt="" className="w-full" />
          <div className="mt-4">Afternoon.では、</div>
          <div
            className="text-orange text-16px font-black mt-2 leading-7"
            style={{ lineHeight: "26px" }}
          >
            <div className="flex mb-2">
              <img src={Beer} alt="" />
              <div className="ml-2">ランチ・飲み友達</div>
            </div>
            <div className="flex mb-2">
              <img src={Bubble} alt="" />
              <div className="ml-2">愚痴や相談ができる友達</div>
            </div>
            <div className="flex">
              <img src={Tennis} alt="" />
              <div className="ml-2">趣味のお友達</div>
            </div>
          </div>
          <div className="mt-2 text-16px">
            を求める皆様が集まっています！
            <br />
            家族や友人以外の方々と、既婚者同士ならではの会話をするのは大切な時間です。
          </div>
        </div>
      </FirstSection>
      <div className="mt-14">
        {/* 「Afternoon.では、あなたと同じような境遇のお友達とつながれます」セクション */}
        <section className="mt-5 px-4">
          <h2 className="text-24px font-black">
            Afternoon.では、あなたと同じような境遇のお友達とつながれます
          </h2>
          <div className="text-13px font-black mt-6">
            <div className="flex items-start mb-6">
              <img src={Couple} alt="" className="pt-1" />
              <div className="ml-4">
                <div className="ml-2 text-18px">年齢層は30~50代が中心</div>
                <div className="ml-2 mt-2 text-14px font-medium leading-5">
                  他のコミュニティサービスよりも、大人同士で知り合うことができます。
                </div>
              </div>
            </div>
            <div className="flex items-start mb-6">
              <img src={Alarm} alt="" className="pt-2" />
              <div className="ml-4">
                <div className="ml-2 text-18px">同じような生活リズム</div>
                <div className="ml-2 mt-2 text-14px font-medium leading-5">
                  既婚者限定のため、同じような生活リズム・行動範囲のお友達と効率的に交流することができます。
                </div>
              </div>
            </div>
            <div className="flex items-start mb-2">
              <div className="h-full w-10 pt-1">
                <img src={Bubble} className="w-10 h-10" alt="" />
              </div>

              <div className="ml-4 flex-1">
                <div className="ml-2 text-18px">悩みを理解し合える</div>
                <div className="ml-2 mt-2 text-14px font-medium leading-5">
                  既婚者同士、同じ境遇だからこそ理解し合える友達関係を気づくことができます。
                </div>
              </div>
            </div>
          </div>
          {/* たくさんのお友達ができていますセクション */}
          <section className="mt-16">
            <h2 className="text-24px font-black mb-4">
              たくさんのお友達ができています
            </h2>
            <div
              className="py-6 px-14 rounded-2xl"
              style={{ backgroundColor: "#FFF6EB" }}
            >
              <div className="text-13px font-black mt-2">
                <div className="flex mb-6">
                  <img src={EmojiCouple} alt="" />
                  <div className="ml-4">
                    <div className="ml-2 text-14px">累計フレンド成立数</div>
                    <div className="ml-2  text-30px text-orange font-black flex leading-7">
                      150,000
                      <span className="text-black text-16px pt-1">件</span>
                    </div>
                  </div>
                </div>
                <div className="flex mb-6">
                  <img src={Mail} alt="" />
                  <div className="ml-4">
                    <div className="ml-2 text-14px">累計メッセージ</div>
                    <div className="ml-2  text-30px text-orange font-black flex leading-7">
                      2,500,000
                      <span className="text-black text-16px pt-1">件</span>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <img src={Hands} alt="" />
                  <div className="ml-4">
                    <div className="ml-2 text-14px">フレンド成立率</div>
                    <div className="ml-2  text-30px text-orange font-black flex leading-7">
                      60
                      <span className="text-black text-16px pt-1">%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-14">
            <h2 className="text-24px font-black mb-6">
              お友達ができるまでの流れ
            </h2>
            <div>
              <div className="flex justify-between">
                <div className="mr-4">
                  <div
                    className="text-12px text-center bg-orange px-1 py-1 w-12 text-white font-bold"
                    style={{ borderRadius: "4px" }}
                  >
                    STEP1
                  </div>
                  <div className="text-18px font-black mt-2">
                    プロフィールを設定しよう
                  </div>
                  <p className="mt-1 text-14px">
                    プロフィールに実名の登録は不要のため、匿名で安心して利用できます。
                  </p>
                </div>
                <img src={SearchUser1} alt="" width={96} height={170} />
              </div>
              <img className="m-auto mt-2" src={chevronRight} alt="" />
              {/* STEP2 */}
              <div className="flex mt-3">
                <div className="mr-4">
                  <div
                    className="text-12px text-center bg-orange px-1 py-1 w-12 text-white font-bold"
                    style={{ borderRadius: "4px" }}
                  >
                    STEP2
                  </div>
                  <div className="text-18px font-black mt-2">
                    掲示板で、本音で意見を交換しよう
                  </div>
                  <p className="mt-1 text-14px">
                    好きなこと、日常の悩み相談、雑談等、各ジャンルに沿って交流ができます。
                    掲示板ではプロフィールのニックネームが伏せられているため、より本音で話すことができます。
                  </p>
                </div>
                <img src={SearchUser2} alt="" width={96} height={170} />
              </div>
              <img className="m-auto mt-2" src={chevronRight} alt="" />
              {/* STEP3 */}
              <div className="flex mt-3">
                <div className="mr-4">
                  <div
                    className="text-12px text-center bg-orange px-1 py-1 w-12 text-white font-bold"
                    style={{ borderRadius: "4px" }}
                  >
                    STEP3
                  </div>
                  <div className="text-18px font-black mt-2">
                    仲良くなったお友達とメッセージのやり取りをしよう
                  </div>
                  <p className="mt-1 text-14px">
                    掲示板で仲良くなった方とフレンドになると、個別でメッセージのやり取りができるようになります。
                  </p>
                </div>
                <img src={SearchUser3} alt="" width={96} height={170} />
              </div>
            </div>
          </section>
        </section>
        {/* 安心安全の取り組み */}
        <section className="px-4">
          <h2 className="font-black text-24px mt-14">安心・安全の取り組み</h2>
          <div className="flex items-start my-6">
            <img
              src={Shield}
              alt=""
              style={{ width: "40px", height: "45px" }}
            />
            <div className="ml-4">
              <p className="font-black text-18px ml-2 flex-1">
                24時間365日の監視体制
              </p>
              <p className="ml-2 mt-2 text-14px font-medium">
                サクラ行為を行う業者や迷惑行為を徹底排除
              </p>
            </div>
          </div>
          <div className="flex items-start my-6">
            <img src={PerformingArt} alt="" />
            <div className="ml-4">
              <p className="font-black text-18px ml-2">安心のニックネーム制</p>
              <p className="ml-2 mt-2 text-14px font-medium">
                プロフィールに実名の登録は不要のため、匿名で利用可能
              </p>
            </div>
          </div>
          <div className="flex items-start mt-6">
            <img src={Memo} alt="" />
            <div className="ml-4">
              <p className="font-black text-18px ml-2">年齢確認</p>
              <p className="ml-2 mt-2 text-14px font-medium">
                メッセージのやり取りをするには、公的身分証明書での年齢確認が必要です。
                <br />
                個人情報の取り扱いは厳重に管理しております。
                <br />
                20歳未満のご利用は禁止しております。
              </p>
            </div>
          </div>
        </section>
        {/* 体験談 */}
        <section
          className="mt-10 px-4 py-8"
          style={{ backgroundColor: "#FFF6EB" }}
        >
          <h2 className="text-24px font-black mb-6">体験談</h2>
          <div className="flex bg-white rounded-2xl p-5">
            <div className="w-12">
              <img src={User01} alt="" />
              <div className="text-12px text-center mt-2">
                45歳
                <br />
                男性
              </div>
            </div>
            <div
              className="flex-1 ml-4 text-14px"
              style={{ lineHeight: "20px" }}
            >
              家の近くに妻の実家があり、子供が生まれてからは妻がそちらの方へ行ってしまい、会話が減ってしまいました。義父との関係は悪くはないのですが、なかなかそちらへは入って行けず。
              同じような環境の方や日常の愚痴を話せる人を探して登録してみました。
              <span className="text-orange font-black">
                今では愚痴を言い合う仲のお友達が見つかり、また掲示板で色々な人の意見や悩みを見るのが日課となり、リフレッシュできています。
              </span>
            </div>
          </div>
          <div className="flex bg-white rounded-2xl p-5 mt-4">
            <div className="w-12">
              <img src={User02} alt="" />
              <div className="text-12px text-center mt-2">
                34歳
                <br />
                女性
              </div>
            </div>
            <div
              className="flex-1 ml-4 text-14px"
              style={{ lineHeight: "20px" }}
            >
              旦那から仕事と育児の両立を求められ、そのことでよく夫婦喧嘩になっていました。自分が正しいとは思うけど、あっちの言い分も間違ってはいない気がする…😓男友達がいない私は相談相手がいなかったけど、
              <span className="text-orange font-black">
                このサイトで出会ったお友達に、男性目線で意見を言ってもらっています。人によって意見が全然違うので「人それぞれ考え方は違うんだな」と当たり前のことを改めて再発見できました。
              </span>
              夫婦喧嘩は、、少し減ったかな笑😁
            </div>
          </div>
          <div className="flex bg-white rounded-2xl p-5 mt-4">
            <div className="w-12">
              <img src={User03} alt="" />
              <div className="text-12px text-center mt-2">
                48歳
                <br />
                女性
              </div>
            </div>
            <div
              className="flex-1 ml-4 text-14px"
              style={{ lineHeight: "20px" }}
            >
              子育てもひと段落したので、趣味友探しに登録しました。登録して1週間ぐらいで大勢の人とフレンドになれたことにびっくり！一応全員とメッセージのやり取りしたものの、会話を続けるのって結構難しいものですね😓でもその中の何人かとは自然に会話が出来たので、
              <span className="text-orange font-black">
                空き時間にはいつもメッセージのやり取りして、すごく癒される大切な時間になってます。
              </span>
              あ、その内映画の趣味がすごく合う友達と今度一緒に映画を見に行きますよ✨
            </div>
          </div>
        </section>
        {/* よくあるご質問 */}
        <section className="mt-8 px-4">
          <h2 className="text-24px font-black">よくあるご質問</h2>
          <div className="mt-6">
            <div>
              <p className="text-orange font-black text-16px">
                Afternoon.はどんなサービスですか？
              </p>
              <div className="text-16px mt-2 leading-5">
                結婚後でも、既婚者同士でお友達を作り人生を充実させたい方が利用する既婚者限定のお友達探しサービスです。
              </div>
            </div>

            <div className="mt-6">
              <p className="text-orange font-black text-16px">
                どのような人たちが利用しますか？
              </p>
              <div className="text-16px mt-2">
                既婚者の男女30~50代の大人の方が利用します。
              </div>
            </div>
            <div className="mt-6">
              <p className="text-orange font-black text-16px">
                独身でも参加できますか？
              </p>
              <div className="text-16px mt-2 leading-5">
                大変恐れ入りますが、独身の方はお断りさせていただいています。
              </div>
            </div>
            <div className="mt-6">
              <p className="text-orange font-black text-16px">
                入会料はかかりますか？
              </p>
              <div className="text-16px mt-2">
                一切不要です。無料で会員登録ができます。
              </div>
            </div>
            <div className="mt-6">
              <p className="text-orange font-black text-16px">
                不倫目的でのサービスではないですか？
              </p>
              <div className="text-16px mt-2 leading-5">
                不倫の出会いを目的としたサービスではありません。利用者の方が不快に思う行為をされる方はご利用をお断りさせていただいています。
              </div>
            </div>
            <div className="mt-6">
              <p className="text-orange font-black text-16px">
                会員登録時に必要なものはありますか？
              </p>
              <div className="text-16px mt-2 leading-5">
                会員登録時には会員登録用のメールアドレスが必要となります。また、メッセージのやりとりを始める前に、公的身分証明書（運転免許
                / 健康保険証 / パスポート /
                住民基本台帳カード）での年齢確認を必須としています。
              </div>
            </div>
            <div className="mt-6">
              <p className="text-orange font-black text-16px">
                決済手段は何を利用できますか？
              </p>
              <div className="text-16px mt-2 leading-5">
                クレジットカード決済がご利用いただけます。
                <br />
                カード明細には本サービス名称ではなく、決済代行会社の名称が表示されます。
              </div>
            </div>
          </div>
        </section>
        {/* 料金案内 */}

        <section className="mt-12 px-4">
          <h2 className="text-24px font-black">料金案内</h2>
          <div
            className="text-white font-bold text-center py-1 mt-6"
            style={{ backgroundColor: "#6780FF", borderRadius: "4px" }}
          >
            男性
          </div>
          <div className="mt-4 text-14px">
            <table className="text-12px m-auto w-full">
              <thead>
                <tr>
                  <th className="w-10"></th>
                  <th
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                    className="text-16px"
                  >
                    有料会員
                  </th>
                  <th className="text-orange text-16px">VIP会員</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-12">
                  <th className="text-14px font-medium text-left">1ヶ月</th>
                  <th
                    className="text-14px"
                    style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}
                  >
                    ￥9,800/月
                  </th>
                  <td className="text-14px text-center font-black">
                    ￥14,980/月
                  </td>
                </tr>
                <tr className="h-12">
                  <th className="text-14px font-medium text-left">3ヶ月</th>
                  <th style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <span className="tracking-wide text-14px">￥7,800/月</span>
                    <br />
                    <span className="text-10px font-medium tracking-tighter">
                      総額 ￥23,400
                    </span>
                  </th>
                  <td className="text-center text-14px font-black">
                    ￥12,980/月
                    <br />
                    <span className="text-10px font-medium tracking-tighter">
                      総額 ￥38,940
                    </span>
                  </td>
                </tr>
                <tr className="h-12">
                  <th className="text-14px font-medium text-left">6ヶ月</th>
                  <th style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <span className="tracking-wide text-14px">￥5,800/月</span>
                    <br />
                    <span className="text-10px font-medium tracking-tighter">
                      総額 ￥34,800
                    </span>
                  </th>
                  <td className="text-center font-black text-orange">
                    <div
                      className="text-center relative m-auto text-14px whitespace-nowrap"
                      style={{ width: "74px" }}
                    >
                      ￥10,980/月
                      <img
                        src={Badge}
                        alt=""
                        className="absolute"
                        style={{ right: "70px", top: "-15px" }}
                      />
                      <br />
                      <span className="text-10px text-orange font-medium tracking-tighter">
                        総額 ￥65,880
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="h-12">
                  <th className="text-14px font-medium text-left whitespace-nowrap">
                    12ヶ月
                  </th>
                  <th style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <span className="tracking-wide text-14px">￥3,800/月</span>
                    <br />
                    <span className="text-10px font-medium tracking-tighter">
                      総額 ￥45,600
                    </span>
                  </th>
                  <td className="text-center font-black">
                    <span className="tracking-wide text-14px">￥8,980/月</span>
                    <br />
                    <span className="text-10px font-medium tracking-tighter">
                      総額 ￥107,760
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="text-white font-bold text-center py-1 mt-8"
            style={{ backgroundColor: "#FF7967", borderRadius: "4px" }}
          >
            女性
          </div>
          <div className="mt-4 text-12px">
            <table className="text-12px m-auto w-full">
              <thead>
                <tr>
                  <th className="w-1/2"></th>
                  <th className="text-orange w-1/2 text-left pl-2 text-16px">
                    VIP会員
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-12">
                  <th className="text-14px font-medium text-center pl-10">
                    1ヶ月
                  </th>

                  <td className="text-left font-black text-14px">￥1,980/月</td>
                </tr>
                <tr className="h-12">
                  <th className="text-14px font-medium text-center pl-10">
                    3ヶ月
                  </th>

                  <td className="text-14px text-left font-black pl-1">
                    ￥980/月
                    <br />
                    <span className="text-10px font-medium tracking-tighter">
                      総額 ￥2,940
                    </span>
                  </td>
                </tr>
                <tr className="h-12">
                  <th className="text-14px font-medium text-center pl-10">
                    6ヶ月
                  </th>
                  <td className="text-left font-black text-orange">
                    <div
                      className="text-14px text-left relative pl-1"
                      style={{ width: "74px" }}
                    >
                      ￥680/月
                      <img
                        src={Badge}
                        alt=""
                        className="absolute"
                        style={{ right: "80px", top: "-15px" }}
                      />
                      <br />
                      <span className="text-10px font-medium tracking-tighter">
                        総額 ￥4,080
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="h-12">
                  <th className="text-14px font-medium text-center pl-10">
                    12ヶ月
                  </th>
                  <td className="text-left font-black pl-1">
                    <span className="text-14px tracking-wide">￥380/月</span>
                    <br />
                    <span className="text-10px font-medium tracking-tighter">
                      総額 ￥4,560
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <PageFooter>
        <Footer />
      </PageFooter>
    </div>
  );
};

const EyeCatch = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  height: 510px;
`;
const Background = styled.img`
  width: 100vw;
  max-width: 600px;
  height: 480px;
  object-fit: cover;
  object-position: top;
  margin: 0 auto;
`;
const Title = styled.div`
  top: 52%;
  left: 12%;
  text-align: center;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  line-height: 26px;
  font-family: Noto Sans JP;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  p {
    font-size: 36px;
    margin-top: 20px;
    font-weight: 900;
  }
  @media screen and (min-width: 600px) {
    left: 26%;
    top: 55%;
  }
`;

const FirstSection = styled.div`
  padding: 0 16px;
  margin-top: 32px;
  @media screen and (min-width: 550px) {
    margin-top: 80px;
  }
`;

const Medal = styled.img`
  position: absolute;
  top: 0;
  right: 12px;
  width: 80px;
  @media screen and (min-width: 600px) {
    right: 42px;
  }
`;

const RegistrationButtonWrapper = styled.div`
  margin: 0 auto;
  margin-top: -40px;
  display: flex;
  justify-content: center;
  margin-bottom: 150px;
  max-width: 600px;
  width: 100%;
  @media screen and (min-width: 600px) {
    margin-top: -50px;
  }
`;

const RegistrationButtonWrapperFix = styled.div<{ displayFlg: boolean }>`
  display: ${({ displayFlg }) => (displayFlg ? "none" : "flex")};
  justify-content: center;
  position: fixed;
  bottom: 5%;
  width: 100%;
  max-width: 600px;
`;

const Header = styled.header`
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  .buttons {
    width: 186px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 501;
  }
  .planButton,
  .loginButton {
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 4px;
    display: block;
  }
  .planButton {
    width: 97px;
    border: 1px solid ${colors.boundary};
    box-sizing: border-box;
  }
  .loginButton {
    margin-left: 8px;
    width: 81px;
  }
`;

const AppExplanation = styled.p`
font-family: Noto Sans JP;
font-size: 16px;
line-height: 26px
Align: Left
vertical-align:center;
margin-top: 16px;
`;

const PageFooter = styled.footer`
  margin: 40px auto 0;
`;

const SwingAnimationImg = styled.img`
  animation-name: move;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  @keyframes move {
    0% {
    }
    5% {
      transform: translate(3px);
    }
    10% {
      transform: translate(-3px);
    }
    15% {
      transform: translate(3px);
    }
    20% {
      transform: translate(-3px);
    }
    25% {
      transform: translate(3px);
    }
    30% {
      transform: translate(0px);
    }
  }
`;
export default MakeLandingPageComponent;
