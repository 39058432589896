import "./App.css";
import LandingPage from "./pages";
import SingupPage from "./pages/signup/input";
import SingupConfirmPage from "./pages/signup/confirm";
import LawPage from "./pages/law";
import HelpListPage from "./pages/helplist";
import CorpPage from "./pages/corp";
import { BrowserRouter, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Route path="/" component={LandingPage} exact></Route>
      <Route path="/signup/input" component={SingupPage} exact></Route>
      <Route path="/signup/confirm" component={SingupConfirmPage} exact></Route>
      <Route path="/helplist" component={HelpListPage} exact></Route>
      <Route path="/law" component={LawPage} exact></Route>
      <Route path="/corp" component={CorpPage} exact></Route>
    </BrowserRouter>
  );
}

export default App;
