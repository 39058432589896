import Repository from "./repository";

export class UserRepository {
  async signup(email: string): Promise<{ status: number }> {
    const res = await Repository.post("apiv3/user/preRegist", { email: email });
    return { status: res.status };
  }
}

export const userRepo = new UserRepository();
