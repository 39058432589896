import styled from "styled-components";
import { colors } from "../../styles";

import BgCorp from "../../compoenents/designSystems/Images/BgCorp.png";
import Logo from "../../compoenents/designSystems/Images/Logo.png";
import { AnonymousLayout } from "../../compoenents/common/Layout";
import { ReactComponent as Logotype } from "../../compoenents/designSystems/Images/logotype_white.svg";

const FirstViewWrap = styled.div`
  height: 720px;
  max-height: calc(100vh - 48px);
  text-align: center;
  background-image: url(${BgCorp});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FirstView = styled.div`
  color: #fff;

  img {
    width: 72px;
    margin: 0 auto;
    display: block;
  }

  svg {
    width: 223px;
    height: 39px;
    margin-top: 20px;
    display: block;
  }

  p {
    line-height: 26px;
    margin: 32px 0 0;
    padding: 0;
    font-size: 18px;
    color: #fff;
  }
`;

const InfoTalbe = styled.dl`
  margin: 24px 0 0;
  padding: 0;
`;

const InfoRow = styled.div`
  line-height: 22px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  display: flex;

  dt {
    width: 64px;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }

  dd {
    margin: 0 0 0 8px;
    padding: 0;
    color: ${colors.textMedium};
  }
`;

const SectionWrap = styled.div`
  padding: 24px 16px 32px;

  h2 {
    margin: 0;
    padding: 0 0 8px;
    font-size: 16px;
    border-bottom: 2px solid ${colors.theme};
    display: inline-block;
  }

  ${InfoTalbe} + ${InfoTalbe} {
    margin-top: 16px;
  }
`;

const Corp = () => {
  return (
    <AnonymousLayout>
      <FirstViewWrap>
        <FirstView>
          <img src={Logo} alt="Afternoon Logo" />
          <Logotype width={223} height={39} />
          <p className="font-bold">
            新たな出会いの場を創出し、
            <br /> 輝き続ける人生を演出する。
          </p>
        </FirstView>
      </FirstViewWrap>

      <SectionWrap>
        <h2 className="font-bold">会社概要</h2>

        <InfoTalbe>
          <InfoRow>
            <dt>会社名</dt>
            <dd>株式会社アフターヌーン</dd>
          </InfoRow>
          <InfoRow>
            <dt>所在地</dt>
            <dd>
              〒142-0061
              <br />
              東京都品川区小山台一丁目12番9号
            </dd>
          </InfoRow>
          <InfoRow>
            <dt>代表者</dt>
            <dd>田之上幸輝</dd>
          </InfoRow>
          <InfoRow>
            <dt>事業内容</dt>
            <dd>Webサービス開発・運営</dd>
          </InfoRow>
        </InfoTalbe>
      </SectionWrap>
    </AnonymousLayout>
  );
};

export default Corp;
