import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { colors, fontFamilie } from './variables';

export const GlobalStyle = createGlobalStyle`
  ${normalize};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    min-height: 100%;
    color: ${colors.textDark};
    font-family: ${fontFamilie};
    font-size: 10px;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }
`;
