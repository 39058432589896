import React from "react";
import styled from "styled-components";

import { History } from "history";
import { colors } from "../../styles/variables";
import { AnonymousLayout } from "../../compoenents/common/Layout";

interface Props {
  history: History;
}

const Law: React.FC<Props> = ({ history }) => (
  <AnonymousLayout
    appBarOptions={{
      history,
      children: "特定商取引法に基づく表記",
    }}
  >
    <Wrapper>
      <Block>
        <TitleSecondary>1. 販売業者</TitleSecondary>
        <p className="text">株式会社アフターヌーン</p>

        <TitleSecondary>2.会社名</TitleSecondary>
        <p className="text">株式会社アフターヌーン</p>

        <TitleSecondary>3. 販売責任者</TitleSecondary>
        <p className="text">田之上 幸輝</p>

        <TitleSecondary>4. 所在地</TitleSecondary>
        <p className="text">東京都品川区小山台一丁目12番9号</p>

        <TitleSecondary>5. 連絡先</TitleSecondary>
        <p className="text">info@after-noon.jp</p>
        <p className="text">
          お問い合わせ順に対応しておりますが、混雑時などは回答までお時間をいただくことがあります。あらかじめご了承ください。
        </p>
        <p className="text">営業時間: 10:00～19:00 (土日祝日除く)</p>

        <TitleSecondary>6.サービス利用料金</TitleSecondary>
        <p className="text">
          サービス内の決済ページにて表示する価格をご参照下さい。
        </p>

        <TitleSecondary>7.サービス利用料以外の必要料金</TitleSecondary>
        <p className="text">
          インターネット接続には別途、ご契約の通信事業者への通信料が発生します。
        </p>
        <p className="text">
          携帯電話からのご利用には、携帯通信料又はパケット通信料が発生します。
        </p>

        <TitleSecondary>8.支払方法及び時期</TitleSecondary>
        <p className="text">クレジットカード決済。</p>
        <p className="text">手続き完了後即時お支払が発生します。</p>

        <TitleSecondary>9.商品の返品・交換</TitleSecondary>
        <p className="text">
          サービス利用料金の返金は、サービスの性質上いかなる場合も行いません。
        </p>
      </Block>
    </Wrapper>
  </AnonymousLayout>
);

const Wrapper = styled.div`
  padding: 16px;
  background: ${colors.background};
`;

const Block = styled.div`
  padding: 32px 24px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: ${colors.surface};

  :nth-child(n + 2) {
    margin-top: 16px;
  }

  .text {
    font-size: 12px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    color: ${colors.textMedium};
  }
`;

const TitleSecondary = styled.h2`
  font-size: 14px;
  font-weight: bold;
  margin: 32px 0 16px;
`;

export default Law;
