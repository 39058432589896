import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { History } from "history";
import { colors } from "../../styles/variables";
import MessageFill from "../../compoenents/designSystems/Icons/MessageFill.svg";
import { AnonymousLayout } from "../../compoenents/common/Layout";

interface Props {
  history: History;
}

const HelpList: React.FC<Props> = ({ history }) => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const el = ref.current as HTMLElement;
    el.style.mask = `url(${MessageFill}) no-repeat 50% 50%`;
    el.style.webkitMask = `url(${MessageFill}) no-repeat 50% 50%`;
  });
  return (
    <AnonymousLayout
      appBarOptions={{
        history,
        children: "お問い合わせ",
      }}
    >
      <Wrapper>
        <Contact>
          <ContactTitle>
            <span
              ref={ref}
              className="w-5 h-5 mr-2"
              style={{ backgroundColor: "rgb(230, 178, 126)" }}
            />
            お問い合わせについて
          </ContactTitle>
          <p>
            以下メールアドレス宛にお問い合わせ内容を記載頂き、ご連絡をお願いいたします。
          </p>
          <p>support@after-noon.jp</p>
        </Contact>
      </Wrapper>
    </AnonymousLayout>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 48px - 233px);
  padding: 24px 24px 48px 24px;
  background: ${colors.background};
`;

const Contact = styled.div`
  padding: 24px;
  background: ${colors.surface};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  p {
    font-size: 14px;
    line-height: 22px;
    color: ${colors.textMedium};
    margin-top: 10px;
  }
`;

const ContactTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  svg {
    color: ${colors.theme};
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;
export default HelpList;
