/**
 * ブラウザーがSafariかを確認する
 * @returns
 */
export const isSafari = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  console.log('ua', ua);
  if (
    ua.indexOf('chrome') !== -1 &&
    ua.indexOf('chrome') < ua.indexOf('safari')
  ) {
    return false;
  } else if (ua.indexOf('safari') !== -1) {
    return true;
  } else if (
    ua.indexOf('edge') !== -1 ||
    ua.indexOf('edga') !== -1 ||
    ua.indexOf('edgios') !== -1
  ) {
    return false;
  } else if (ua.indexOf('opera') !== -1 || ua.indexOf('opr') !== -1) {
    return false;
  } else if (ua.indexOf('samsungbrowser') !== -1) {
    return false;
  } else if (ua.indexOf('ucbrowser') !== -1) {
    return false;
  } else if (ua.indexOf('firefox') !== -1 || ua.indexOf('fxios') !== -1) {
    return false;
  } else if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1) {
    return false;
  } else {
    return false;
  }
  return false;
};

export const isIphone = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  console.log('ua', ua);
  if (ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1) {
    return true;
  } else if (ua.indexOf('windows nt') !== -1) {
    return false;
  } else if (ua.indexOf('android') !== -1) {
    return false;
  } else if (ua.indexOf('mac os x') !== -1) {
    return true;
  } else {
    return false;
  }
};
