import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../compoenents/designSystems/Button";
import { colors } from "../../styles";

import { Typography } from "../../compoenents/designSystems/Typography";
import { Input as InputField } from "../../compoenents/designSystems/TextFields";
import { AnonymousLayout } from "../../compoenents/common/Layout";
import { userRepo } from "../../repositories/userRepository";
import { useHistory } from "react-router-dom";

const Input: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const submitPreRegist = async () => {
    setHasError(false);
    const res = await userRepo.signup(email).catch((e) => {
      setHasError(true);
    });
    if (res) {
      history.push("/signup/confirm");
    }
  };
  return (
    <AnonymousLayout>
      <StyledDiv>
        <Typography level={5} className="Typography">
          新規会員登録
        </Typography>
        <Message>
          ご利用いただくには会員登録が必要です。
          <br />
          会員登録のためのメールをお送りします。
          <br />
          Gmail等メーラーによっては、送信したメールが迷惑メールと自動的に判断されるケースがございます。
          <br />
          その場合は迷惑メールフォルダにメールが届いてないかご確認ください。
        </Message>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (!email) {
              return;
            }
            submitPreRegist();
          }}
        >
          <InputField
            label="メールアドレス"
            placeholder="メールアドレスを入力"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {hasError && (
            <p className="text-12px" style={{ color: "rgb(229, 26, 13)" }}>
              エラーが発生しました
            </p>
          )}
          <StyledButton color="theme" padding="17px 14px">
            招待メールを送信
          </StyledButton>
          <Login>
            すでに会員の方は
            <a href="https://www.after-noon.jp/login" rel="noreferrer">
              ログイン
            </a>
          </Login>
        </form>
      </StyledDiv>
    </AnonymousLayout>
  );
};

const StyledDiv = styled.div`
  padding: 28px 24px 56px;
  border-top: 1px solid ${colors.boundary};

  form {
    margin-top: 19px;
  }
`;

const Message = styled.p`
  line-height: 26px;
  margin: 30px 0 30px;
  padding: 0;
  font-size: 13px;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const Login = styled.p`
  line-height: 26px;
  margin: 40px 0 0;
  padding: 0;
  font-size: 13px;
  text-align: center;

  a {
    font-weight: bold;
    color: ${colors.theme};
  }
`;

export default Input;
