import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, zIndex } from "../../../styles";
import Logotype from "../Images/logotype_header.png";
interface ApplicationBarProps {
  children?: React.ReactNode;
  leftIcon?: JSX.Element;
  fix?: boolean;
}

const ApplicationBar: React.FC<ApplicationBarProps> = ({
  leftIcon,
  fix,
  children,
}) => {
  return (
    <ApplicationWrapper fix={!!fix}>
      {leftIcon && <div className="icon">{leftIcon}</div>}
      <div className="title">{children}</div>
    </ApplicationWrapper>
  );
};

interface LogoBarProps {
  rightStuff?: JSX.Element;
  fix?: boolean;
}

const LogoBar: React.FC<LogoBarProps> = ({ rightStuff, fix }) => {
  return (
    <LogoWrapper fix={!!fix}>
      <Link to="/" className="logo">
        <img src={Logotype} />
      </Link>
      {rightStuff && <div className="rightStuff">{rightStuff}</div>}
    </LogoWrapper>
  );
};

const ApplicationWrapper = styled.div<{ fix: boolean }>`
  position: ${({ fix }) => (fix ? "fixed" : "relative")};
  display: flex;
  align-items: center;
  max-width: 672px;
  height: 48px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid ${colors.boundary};
  z-index: ${zIndex.fixedBar};

  .title {
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }

  .icon {
    position: absolute;
    left: 19px;
  }
`;

const LogoWrapper = styled.div<{ fix: boolean }>`
  position: ${({ fix }) => (fix ? "fixed" : "relative")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 16px;
  width: 100vw;
  height: 48px;
  z-index: ${zIndex.fixedBar};

  .logo {
    flex-shrink: 0;
  }
`;

export { ApplicationBar, LogoBar };
