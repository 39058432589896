import * as React from 'react';
import styled from 'styled-components';
import { colors } from "../../../styles/variables";

interface Props {
  children?: string | JSX.Element;
  icon?: JSX.Element;
  className?: string;
  color?: 'white' | 'theme' | 'gradient';
  onClick?: () => void;
}

export const FloatingButton: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  icon,
  className,
  color,
  ...restProps
}) => {
  return (
    <Wrapper className={className} color={color} {...restProps}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  position: sticky;
  bottom: 60px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  height: 40px;
  background: ${props =>
    props.color === 'white' ? colors.surface : props.color === 'theme' ? colors.theme : colors.themeGradient};
  color: ${props => (props.color === 'white' ? colors.textMedium : colors.surface)};
  font-weight: bold;
  border: 0;
  border-radius: 100px;
  box-shadow: 0 8px 16px ${colors.boundary};

  &:hover {
    opacity: 0.7;
  }

  .icon {
    padding-right: 13px;
  }
`;
