export const viewports: { [key: string]: number } = {
  tablet: 768,
};

export const fontFamilie = `'Noto Sans JP', sans-serif`;

export const gradient = {
  primary: {
    from: '#ffba73',
    to: '#e08163',
  },
  secondary: {
    from: '#386fc1',
    to: '#4a0085',
  },
} as const;

export const colors = {
  textDark: '#1a1a1a',
  textMedium: 'rgba(26, 26, 26, 0.6)',
  textLight: 'rgba(25, 25, 25, 0.35)',
  theme: '#e6B27e',
  themeGradient: `linear-gradient(93.58deg, ${gradient.primary.from} 0%, ${gradient.primary.to} 100%)`,
  attentionGreen: '#5bcf00',
  attentionRed: '#e51a0d',
  attentionPink: '#ffefed',
  attentionBrown: '#e58e27',
  attentionCream: '#fff6eb',
  attentionOrange: '#ed8767',
  boundary: 'rgba(0, 0, 0, 0.1)',
  surface: '#fff',
  background: '#f5f4f2',
  shadeOver: 'rgba(9, 10, 12, 0.4)',
  shadeDark:
    'linear-gradient(360deg, rgba(24, 21, 17, 0.65) 0%, rgba(24, 21, 17, 0) 100%)',
  shadeLight:
    'linear-gradient(360deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)',
  online: '#5bcf00',
  offline: 'rgba(25, 25, 25, 0.35)',
} as const;

export const darkColors = {
  textLight: '#fff',
  textMedium: 'rgba(255, 255, 255, 0.7)',
  textDark: 'rgba(255, 255, 255, 0.5)',
  boundary: 'rgba(255, 255, 255, 0.1)',
  surface: '#333',
  background: '#262626',
} as const;

export const zIndex = {
  fixedBar: '500',
  sideMenu: '510',
  modal: '550',
  halfModal: '560',
  dialog: '600',
  popup: '610',
  fullScreenIndicator: '700',
} as const;
