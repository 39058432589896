import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/variables";

interface Props {
  color: "gray" | "red" | "theme";
  onClick: () => void;
  children: React.ReactNode;
}

export const TextButton: React.FC<Props> = ({ children, color, onClick }) => {
  return (
    <Container color={color} onClick={onClick}>
      {children}
    </Container>
  );
};

const Container = styled.button<{ color: Props["color"] }>`
  color: ${({ color }) =>
    color === "gray"
      ? colors.textMedium
      : color === "red"
      ? colors.attentionRed
      : colors.theme};
  border: 0;
  background-color: transparent;
  padding: 10px 8px;
  font-size: 14px;
  font-weight: bold;
  min-width: 64px;
  text-align: center;
`;
