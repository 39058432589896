import * as React from 'react';
import styled from 'styled-components';

interface TypographyProps {
  level: number;
  className?: string;
  children: React.ReactNode;
}

const TypographyH1 = styled.h1`
  margin: 0;
  padding: 0;
  line-height: 81px;
  font-size: 56px;
`;

const TypographyH2 = styled.h2`
  margin: 0;
  padding: 0;
  line-height: 58px;
  font-size: 40px;
`;

const TypographyH3 = styled.h3`
  margin: 0;
  padding: 0;
  line-height: 46px;
  font-size: 32px;
`;

const TypographyH4 = styled.h4`
  margin: 0;
  padding: 0;
  line-height: 35px;
  font-size: 24px;
`;

const TypographyH5 = styled.h5`
  margin: 0;
  padding: 0;
  line-height: 29px;
  font-size: 20px;
`;

const TypographyH6 = styled.h6`
  margin: 0;
  padding: 0;
  line-height: 28px;
  font-size: 16px;
`;

export const Typography: React.FC<TypographyProps> = ({
  level,
  className,
  children,
}) => {
  return (
    <>
      {(() => {
        if (level === 1) {
          return <TypographyH1 className={className}>{children}</TypographyH1>;
        }
        if (level === 2) {
          return <TypographyH2 className={className}>{children}</TypographyH2>;
        }
        if (level === 3) {
          return <TypographyH3 className={className}>{children}</TypographyH3>;
        }
        if (level === 4) {
          return <TypographyH4 className={className}>{children}</TypographyH4>;
        }
        if (level === 5) {
          return <TypographyH5 className={className}>{children}</TypographyH5>;
        }
        if (level === 6) {
          return <TypographyH6 className={className}>{children}</TypographyH6>;
        }

        return '';
      })()}
    </>
  );
};
