import React from "react";
import { History } from "history";
import ArrowBack from "../../designSystems/Icons/arrowBack.svg";
import { ApplicationBar, LogoBar } from "../../designSystems/Appbars";
import { Footer } from "../../designSystems/Blocks";
import { useHistory } from "react-router-dom";
interface Props {
  children: React.ReactNode;
  appBarOptions?: {
    history: History;
    children: React.ReactNode;
  };
}

export const AnonymousLayout: React.FC<Props> = ({
  appBarOptions,
  children,
}) => {
  const history = useHistory();
  return (
    <div style={{ maxWidth: "600px", margin: "0 auto" }}>
      {appBarOptions ? (
        <ApplicationBar
          // @ts-ignore
          leftIcon={<img src={ArrowBack} onClick={history.goBack} alt="" />}
        ></ApplicationBar>
      ) : (
        <LogoBar />
      )}
      {children}
      <Footer />
    </div>
  );
};
